<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs10 text-left>
            <span class="mainHeader">PROFIT & LOSS</span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-layout wrap>
        <v-flex xs12 sm5 md5 py-6>
          <v-card
            tile
            class="pa-4 rounded-xl profitlossshadow"
            :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
            :flat="$route.name == 'Dashboard' ? true : false"
          >
            <v-layout wrap justify-center>
              <v-flex
                xs12
                md5
                py-2
                class="subhead4"
                style="text-transform: uppercase"
              >
                <v-autocomplete
                  color="#b234a9"
                  :items="customerlist"
                  v-model="month"
                  placeholder="MONTH -- YEAR"
                  item-text="supplierName"
                  item-value="_id"
                  solo
                  flat
                  hide-details
                  dense
                ></v-autocomplete
              ></v-flex>
              <v-flex xs12 sm10 id="chart" align-self-center py-4>
                <apexchart
                  type="pie"
                  max-width="300"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex
                xs12
                py-2
                class="subhead4"
                style="text-transform: uppercase"
              >
                Statement of Financial <br />
                Performance</v-flex
              >
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex xs12 sm10 pa-3 pb-10>
                <v-layout wrap class="subhead5 py-2">
                  <v-flex
                    xs1
                  >
                  <v-icon color="#f036de">
                    mdi-checkbox-blank-circle
                  </v-icon>
                </v-flex>
                  <v-flex xs5 text-left pl-4>Sales : </v-flex>
                  <v-flex xs6> ₹ 233423 </v-flex>
                </v-layout>
                <v-layout wrap class="subhead5 py-2">
                  <v-flex
                    xs1
                  >
                  <v-icon color="#d585cd">
                    mdi-checkbox-blank-circle
                  </v-icon>
                </v-flex>
                  <v-flex xs5 text-left pl-4> Purchase : </v-flex>
                  <v-flex xs6> ₹ 233423 </v-flex>
                </v-layout>
                <v-layout wrap class="subhead5 py-2">
                  <v-flex
                    xs1
                  >
                  <v-icon color="#7b2182">
                    mdi-checkbox-blank-circle
                  </v-icon>
                </v-flex>
                  <v-flex xs5 text-left pl-4> Return : </v-flex>
                  <v-flex xs6> ₹ 233423 </v-flex>
                </v-layout>
                <v-layout wrap class="subhead5 py-2">
                 
                  <v-flex
                    xs1
                  >
                  <v-icon color="#8b0b84">
                    mdi-checkbox-blank-circle
                  </v-icon>
                </v-flex>
                  <v-flex xs5 text-left pl-4> Aciding : </v-flex>
                  <v-flex xs6 > ₹ 233423 </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 sm7 md7 py-6 pl-0 pl-sm-7>
          <v-card
            tile
            
            class="pa-4 rounded-xl profitlossshadow"
            :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
            :flat="$route.name == 'Dashboard' ? true : false"
          >
            <v-layout wrap justify-end>
              <v-flex
                xs12
                md3
                sm3
                py-4
                class="subhead4"
                style="text-transform: uppercase"
              >
                <v-autocomplete
                  color="#b234a9"
                  :items="customerlist"
                  v-model="monthlist"
                  placeholder="MONTH"
                  item-text="supplierName"
                  item-value="_id"
                  solo
                  flat
                  hide-details
                  dense
                ></v-autocomplete
              ></v-flex>
            </v-layout>
            <v-layout wrap v-for="i in 4" :key="i">
              <v-flex xs12>
                <v-expansion-panels flat class="rounded-xl pt-5">
                  <v-expansion-panel style="background-color: #f8f4ff">
                    <v-expansion-panel-header class="expanhead rounded-xl">
                      Week
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <template>
                        <v-data-table
                          :headers="headers"
                          :items="rows"
                          hide-default-header
                          hide-default-footer
                          class="elevation-0 expanhead"
                          style="border-bottom: #05cd99"
                        >
                          <template v-slot:item.profitpercent="{ item }">
                            <span style="color: #05cd99">
                              + {{ item.profitpercent }}%
                            </span>
                          </template>
                          <template v-slot:item.losspercent="{ item }">
                            <span style="color: #cd0505">
                              - {{ item.losspercent }}%
                            </span>
                          </template>
                        </v-data-table>
                      </template>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-layout>
  </div>
</template>
<script>
// import axios from "axios";

export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      username: null,
      keyword: null,
      name: null,
      address: null,
      phone: null,
      email: null,
      showField1: false,
      showField2: false,
      showField3: false,
      showField4: false,
      month: null,
      row: null,
      monthlist: null,

      series: [44, 55, 43, 22],
      chartOptions: {
        colors: ["#D585CD", "#F036DE", "#7B2182", "#8b0b84", "#3F053C"],
        chart: {
          width: 380,
          type: "pie",
          expandOnClick: true,
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },

        labels: ["Purchase", "Sales", "Aciding", "Return"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
      },

      headers: [
        {
          text: "Field 1",
          align: "start",
          value: "Day",
        },

        { text: "Field 2", value: "Text1" },
        { text: "Field 3", value: "profitpercent" },
        { text: "Field 4", value: "Text2" },
        { text: "Field 5", value: "losspercent" },
      ],
      rows: [
        {
          Day: "Monday",
          Text1: "Profit",
          profitpercent: 24,
          Text2: "Loss",
          losspercent: 1,
        },
        {
          Day: "Tuesday",
          Text1: "Profit",
          profitpercent: 37,
          Text2: "Loss",
          losspercent: 1,
        },
        {
          Day: "Wednesday",
          Text1: "Profit",
          profitpercent: 23,
          Text2: "Loss",
          losspercent: 7,
        },
        {
          Day: "Thursday",
          Text1: "Profit",
          profitpercent: 67,
          Text2: "Loss",
          losspercent: 8,
        },
        {
          Day: "Friday",
          Text1: "Profit",
          profitpercent: 49,
          Text2: "Loss",
          losspercent: 16,
        },
        {
          Day: "Saturday",
          Text1: "Profit",
          profitpercent: 94,
          Text2: "Loss",
          losspercent: 0,
        },
        {
          Day: "Sunday",
          Text1: "Profit",
          profitpercent: 98,
          Text2: "Loss",
          losspercent: 2,
        },
      ],
    };
  },
  methods: {
    validateInput() {
      if (!this.email) {
        this.msg = "Please provide email";
        this.showsnackbar = true;
        return;
      } else if (!this.name) {
        this.msg = "Please provide name";
        this.showsnackbar = true;
        return;
      } else if (!this.phone) {
        this.msg = "Please provide phone";
        this.showsnackbar = true;
        return;
      } else if (!this.username) {
        this.msg = "Please provide username";
        this.showsnackbar = true;
        return;
      } else if (!this.address) {
        this.msg = "Please provide address";
        this.showsnackbar = true;
        return;
      } else {
        this.stocklist();
      }
    },
    toggleFields() {
      this.showField1 = !this.showField1;
      this.showField2 = !this.showField2;
      this.showField3 = !this.showField3;
      this.showField4 = !this.showField4;
    },
    // stocklist() {
    //   axios({
    //     url: "/stock/getalllist",
    //     method: "get",
    //     data: {
    //       username: this.username,
    //       email: this.email,
    //       jewelleryName: this.name,
    //       phone: this.phone,
    //       location: this.address,
    //     },
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //     params: {
    //       page: this.currentpage,
    //       limit: this.limit,
    //       from: this.from,
    //       to: this.to,
    //     },
    //     // params: {
    //     //   "x-auth-token": localStorage.getItem("token"),
    //     // },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status == true) {
    //         this.array = response.data.data;
    //         this.pages = Math.ceil(response.data.totalLength / this.limit);
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
  },
};
</script>
